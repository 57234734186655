
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '../styles/index.css';
import { ErrorBoundary } from '@sentry/nextjs';
import { init } from '@socialgouv/matomo-next';
import { Web3ReactProvider } from '@web3-react/core';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import Web3 from 'web3';
import { cache } from 'web3-provider-patchers';
import AppContextProvider from '../components/AppContext/AppContext';
import ErrorComponent from '../components/ErrorComponent';
import LoadBar from '../components/LoadBar';
import MetronomeContextProvider from '../components/MetronomeContext';
import { TransactionContextProvider } from '../components/TransactionContext';
import { WalletContextProvider } from '../components/WalletContext';
import { connectors } from '../utils/connectors';
const MATOMO_URL = process.env.NEXT_PUBLIC_MATOMO_URL;
const MATOMO_SITE_ID = process.env.NEXT_PUBLIC_MATOMO_SITE_ID;
const getLibrary = provider => new Web3(cache(provider, { cache: new Map() }));
const AppReload = dynamic(() => import('../components/AppReload'), {
    ssr: false
});
const MyApp = function ({ Component, pageProps }) {
    const providerConnectors = connectors.map(({ connector, hooks }) => [
        connector,
        hooks
    ]);
    useEffect(function () {
        if (!MATOMO_URL || !MATOMO_SITE_ID)
            return;
        init({ siteId: MATOMO_SITE_ID, url: MATOMO_URL });
    }, []);
    return (<>
      <LoadBar />
      <Web3ReactProvider connectors={providerConnectors}>
        <WalletContextProvider>
          <TransactionContextProvider>
            <AppContextProvider initialData={pageProps.initialData}>
              {pageProps.initialData ? (<MetronomeContextProvider getLibrary={getLibrary} initialData={pageProps.initialData}>
                  <ErrorBoundary fallback={({ componentStack, resetError }) => (<ErrorComponent componentStack={componentStack} resetError={resetError}/>)}>
                    <Component {...pageProps}/>
                  </ErrorBoundary>
                </MetronomeContextProvider>) : (<Component {...pageProps}/>)}
            </AppContextProvider>
            <AppReload />
          </TransactionContextProvider>
        </WalletContextProvider>
      </Web3ReactProvider>
    </>);
};
const __Next_Translate__Page__193c46a981f__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__193c46a981f__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
    });
  