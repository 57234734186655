var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"gmT4yJ1v6iKBWlB2R1g37"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/nextjs'

// Identify an error that happens inside `eth-block-tracker`, used by both
// `@web3-react/walletconnect-connector` and `@web3-react/walletlink-connector`
// connectors.
//
// This error seems to happen when the connectors receive a new block event but
// are unable to get that block from the Ethereum node, possibly due to timing
// issues.
//
// Unfortunately, this error seems to break the subscription for WalletConnect
// users. See #993.
const isBlockSyncError = exception =>
  // Error as logged in Chrome and Firefox.
  (exception.value === "Cannot read property 'hash' of null" &&
    exception.stacktrace.frames[1].function === 'Array.map') ||
  // Error as logged in Safari/iOS.
  (exception.value === "null is not an object (evaluating 'e.hash')" &&
    exception.stacktrace.frames[1].function === 'map')

const ignoreErrors = ['No matching key. history:']

Sentry.init({
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category.startsWith('ui')) {
      const target = hint.event.target
      const customName = target.dataset.sentry
      const textContent = target.textContent
      const customMessage = `${target.tagName.toLowerCase()}[sentry="${customName}"] - ${textContent}`
      breadcrumb.message = customName ? customMessage : breadcrumb.message
    }
    return breadcrumb
  },
  beforeSend(e) {
    try {
      if (isBlockSyncError(e.exception.values[0])) {
        return null
      }
    } catch (err) {
      return e
    }
    return e
  },
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'staging',
  ignoreErrors,
  integrations: [
    // This overrides the default implementation of the RewriteFrames
    // integration from sentry/nextjs. It adds the decodeURI() to fix a mismatch
    // of sourceMaps in reported issues.
    // ref: https://github.com/getsentry/sentry/issues/19713#issuecomment-696614341
    new RewriteFramesIntegration({
      iteratee(frame) {
        const { origin } = new URL(frame.filename)
        frame.filename = decodeURI(frame.filename.replace(origin, 'app://'))
        return frame
      }
    }),
    new Sentry.Replay({ blockAllMedia: false, maskAllText: false })
  ],
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs. It is set to 100%.
  replaysOnErrorSampleRate: 1.0,
  // This sets the sample rate to be defaulted to 10% (in the .env file).
  replaysSessionSampleRate:
    process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  tracesSampleRate: 1.0
})
